import React from "react";
import { seasonNameConversion } from "../helpers";

class NavBar extends React.Component {
  state = {
    showSeasonMenu: "",
    showCollectionMenu: ""
  };

  changeSelectedCollection = (selectedCollection) => {
    this.toggleCollectionMenuState();
    this.props.updateSelectedCollection(selectedCollection);
  };

  changeSeason = (newSeason) => {
    this.toggleSeasonMenuState();
    this.props.updateSelectedSeason(newSeason);
  };

  changeSubCategory = (newCategory) => {
    this.props.updateSelectedSubCategory(newCategory);
  };

  toggleSeasonMenuState = (event) => {
    if (this.state.showSeasonMenu === "is-active") {
      this.setState({ showSeasonMenu: "" });
    } else {
      this.setState({ showSeasonMenu: "is-active" });
    }
  };

  toggleCollectionMenuState = (event) => {
    if (this.state.showCollectionMenu === "is-active") {
      this.setState({ showCollectionMenu: "" });
    } else {
      this.setState({ showCollectionMenu: "is-active" });
    }
  };

  selectEnglish = (event) => {
    event.preventDefault();
    this.props.updateLanguage("en");
    //localStorage.setItem("language", "en");
    //this.setState({ language: "en" });
  };

  selectFrench = (event) => {
    event.preventDefault();
    this.props.updateLanguage("fr");
    //localStorage.setItem("language", "fr");
    //this.setState({ language: "fr" });
  };

  render() {
    const subCategories =
      Object.keys(this.props.allSeasonsAndSubCategories).length === 0
        ? []
        : this.props.allSeasonsAndSubCategories[this.props.selectedSeason];

    return (
      <React.Fragment>
        <nav
          className='navbar is-fixed-top has-background-white-ter'
          role='navigation'
          aria-label='main navigation'
        >
          <div className='navbar-brand'>
            <a className='navbar-item' href='#'>
              <img src='adidas.png' alt='adidas Booking' height='28' />
            </a>
            {this.props.multiLanguage && (
              <React.Fragment>
                <a
                  className='navbar-item'
                  href='#'
                  onClick={this.selectEnglish}
                  style={
                    this.props.language === "en"
                      ? {
                          textDecoration: "underline",
                          fontWeight: "bold",
                          color: "black"
                        }
                      : {}
                  }
                >
                  EN
                </a>
                <a
                  className='navbar-item'
                  href='#'
                  onClick={this.selectFrench}
                  style={
                    this.props.language === "fr"
                      ? {
                          textDecoration: "underline",
                          fontWeight: "bold",
                          color: "black"
                        }
                      : {}
                  }
                >
                  FR
                </a>
              </React.Fragment>
            )}
          </div>
        </nav>
        <div className='columns is-marginless'>
          <div className='column is-narrow'>
            <div className={`dropdown ${this.state.showSeasonMenu}`}>
              <div className='dropdown-trigger'>
                <button
                  className='button is-black is-default'
                  aria-haspopup='true'
                  aria-controls='dropdown-menu'
                  onClick={this.toggleSeasonMenuState}
                >
                  <span>{seasonNameConversion(this.props.selectedSeason)}</span>
                  <span className='icon is-small'>
                    <i className='fas fa-angle-down' aria-hidden='true'></i>
                  </span>
                </button>
              </div>
              <div className='dropdown-menu' id='dropdown-menu' role='menu'>
                <div
                  className='dropdown-content has-background-white-ter'
                  style={{ overflowY: "auto" }}
                >
                  {this.props.allSeasons.map((season) => {
                    return (
                      <a
                        href='#'
                        className='dropdown-item'
                        key={season}
                        onClick={() => this.changeSeason(season)}
                      >
                        {seasonNameConversion(season)}
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>

            <div
              className={`dropdown is-hidden-desktop ${this.state.showCollectionMenu}`}
            >
              <div className='dropdown-trigger'>
                <button
                  className='button is-black'
                  aria-haspopup='true'
                  aria-controls='dropdown-menu'
                  onClick={this.toggleCollectionMenuState}
                >
                  <span>{this.props.selectedCollection}</span>
                  <span className='icon is-small'>
                    <i className='fas fa-angle-down' aria-hidden='true'></i>
                  </span>
                </button>
              </div>
              <div className='dropdown-menu' id='dropdown-menu' role='menu'>
                <div className='dropdown-content has-background-white-ter'>
                  {this.props.collections.map((collection) => {
                    return (
                      <a
                        href='#'
                        className={`dropdown-item ${
                          this.props.selectedCollection === collection
                            ? "is-active"
                            : ""
                        }`}
                        key={collection}
                        onClick={() =>
                          this.changeSelectedCollection(collection)
                        }
                      >
                        {collection}
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className='column'>
            <div className='tabs is-small is-centered is-marginless is-toggle'>
              <ul>
                {subCategories.map((subCategory) => {
                  return (
                    <li
                      key={subCategory}
                      className={
                        this.props.selectedSubCategory === subCategory
                          ? "is-active"
                          : ""
                      }
                      onClick={() => this.changeSubCategory(subCategory)}
                    >
                      <a
                        style={
                          subCategories.length === 1
                            ? {
                                borderTopLeftRadius: "4px",
                                borderBottomLeftRadius: "4px"
                              }
                            : {}
                        }
                      >
                        {subCategory.replace("_", " ")}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NavBar;
