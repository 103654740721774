import React from "react";
import { netPrice } from "../helpers";

class ArticleBox extends React.Component {
  state = {
    articleNumber: this.props.articleInfo["articleNumber"],
    articleName: this.props.articleInfo["articleName"],
    articleColor:
      this.props.language === "fr" &&
      this.props.articleInfo["color-fr"] !== undefined
        ? this.props.articleInfo["color-fr"]
        : this.props.articleInfo["color"],
    articleRetailPrice: this.props.articleInfo["retailPrice"],
    articlePriceCode: this.props.articleInfo["priceCode"],
    articleSizes:
      this.props.language === "fr" &&
      this.props.articleInfo["sizes-fr"] !== undefined
        ? this.props.articleInfo["sizes-fr"]
        : this.props.articleInfo["sizes"],
    articleDeliveryDate:
      this.props.language === "fr" &&
      this.props.articleInfo["deliveryDate-fr"] !== undefined
        ? this.props.articleInfo["deliveryDate-fr"]
        : this.props.articleInfo["deliveryDate"],
    articleImageURL: this.props.articleInfo["imageURL"],
    limitedSizes:
      this.props.articleInfo["soldOutSizes"] !== undefined &&
      this.props.articleInfo["soldOutSizes"].length > 0 &&
      this.props.articleInfo["soldOutSizes"] !== this.props.articleInfo["sizes"]
        ? true
        : false,
    soldOut:
      this.props.articleInfo["soldOutSizes"] !== undefined &&
      this.props.articleInfo["soldOutSizes"] === this.props.articleInfo["sizes"]
        ? true
        : false
  };
  showModal = () => {
    this.props.updateHighResArticle(this.state.articleNumber);
  };

  render() {
    return (
      <div
        className='box has-background-white'
        style={{
          width: "350px",
          height: "250px",
          margin: "5px",
          display: "flex",
          position: "relative"
        }}
      >
        {this.state.limitedSizes ? (
          <div
            style={{
              width: "100px",
              height: "100px",
              position: "absolute",
              top: "0",
              left: "0"
            }}
          >
            {this.props.language === "fr" ? (
              <img
                src='images/limitedSizesBanner-french.png'
                alt='limitedSizes'
              />
            ) : (
              <img src='images/limitedSizesBanner.png' alt='limitedSizes' />
            )}
          </div>
        ) : (
          ""
        )}

        <div className='columns is-mobile' style={{ width: "100%" }}>
          <div className='column is-7 is-paddingless articleImageBox'>
            <div className='imageWrapper'>
              <img
                className='articleImage'
                src={this.state.articleImageURL}
                alt='articleImage'
              />
            </div>
          </div>
          <div className='column articleInfo is-paddingless '>
            <div className='content'>
              <div style={{ fontSize: "13px", height: "50px" }}>
                {this.state.articleName}
              </div>
              <div style={{ fontSize: "11px", height: "50px" }}>
                {this.state.articleColor}
              </div>
              <div style={{ fontSize: "13px", height: "20px" }}>
                <strong>{this.state.articleNumber}</strong>
              </div>
              <div style={{ height: "25px" }}>
                <span style={{ fontSize: "16px" }}>
                  <strong>
                    $
                    {netPrice(
                      this.state.articleRetailPrice,
                      this.state.articlePriceCode
                    )}
                  </strong>
                </span>
                <span style={{ fontSize: "8px" }}> NET</span>
              </div>
              <div style={{ fontSize: "12px", height: "25px" }}>
                <strong>Sizes:</strong>{" "}
                {this.state.articleSizes.split(",").length > 1
                  ? this.state.articleSizes
                      .split(",")[0]
                      .concat(
                        "-",
                        this.state.articleSizes.split(",")[
                          this.state.articleSizes.split(",").length - 1
                        ]
                      )
                  : this.state.articleSizes.split(",")[0]}
              </div>
              <div style={{ fontSize: "12px", height: "20px" }}>
                {this.state.articleDeliveryDate.substr(1)}
              </div>
              <div>
                <button className='button is-white' onClick={this.showModal}>
                  <i className='fas fa-search-plus'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArticleBox;
