import React from "react";
import NavBarGateKeeper from "./NavBarGateKeeper";
import { getServerPass } from "../base";

class GateKeeper extends React.Component {
  state = {
    passwordCorrect: true,
    serverPass: [],
    language: "en"
  };

  passwordRef = React.createRef();

  attemptLogin = (event) => {
    event.preventDefault();

    if (this.state.serverPass.includes(this.passwordRef.current.value)) {
      sessionStorage.setItem("userPassword", this.passwordRef.current.value);
      this.props.history.push({
        pathname: "/articles",
        data: this.passwordRef.current.value
      });
    } else {
      this.setState({ passwordCorrect: false });
    }
  };

  componentDidMount() {
    getServerPass().then((serverPass) => {
      this.setState({ serverPass: serverPass });
    });
  }

  render() {
    return (
      <React.Fragment>
        <NavBarGateKeeper />
        <div className='hero is-fullheight'>
          <div className='hero-body' style={{ flexWrap: "wrap" }}>
            <div className='container'>
              {/* <div
                className='container'
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "50px"
                }}
              >
                <button
                  className={`button is-large ${
                    this.state.language === "en" ? "is-dark" : "is-light"
                  }`}
                  style={{ marginRight: "10px" }}
                  onClick={this.selectEnglish}
                >
                  English
                </button>
                <button
                  className={`button is-large ${
                    this.state.language === "fr" ? "is-dark" : "is-light"
                  }`}
                  onClick={this.selectFrench}
                >
                  Français
                </button>
              </div> */}
              <div className='container' style={{ maxWidth: "512px" }}>
                <form
                  className='field is-grouped center'
                  onSubmit={this.attemptLogin}
                >
                  <div className='control is-expanded'>
                    <input
                      name='password'
                      ref={this.passwordRef}
                      className={`input is-large ${
                        this.state.passwordCorrect ? null : "is-danger"
                      }`}
                      type='text'
                      required
                      placeholder={
                        this.state.language === "en"
                          ? "Password"
                          : "Mot de passe"
                      }
                    />
                  </div>
                  <div className='control'>
                    <button className='button is-large is-dark' type='submit'>
                      {this.state.language === "en" ? "Enter" : "Entrer"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default GateKeeper;
