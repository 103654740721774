import database from "./base";

export function formatPrice(cents) {
  return (cents / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });
}

export function rando(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}

export function netPrice(retailPrice, priceCode) {
  if (priceCode === "(a)") {
    return (retailPrice * 0.5).toFixed(2);
  } else if (priceCode === "(c)") {
    return (retailPrice * 0.6).toFixed(2);
  } else {
    return "Price Error";
  }
}

export function seasonNameConversion(databaseSeasonName) {
  if (databaseSeasonName.startsWith("Spring")) {
    const newName = databaseSeasonName.replace("Spring", "Spring ");
    return newName;
  } else if (databaseSeasonName.startsWith("Summer")) {
    const newName = databaseSeasonName.replace("Summer", "Summer ");
    return newName;
  } else if (databaseSeasonName.startsWith("SS")) {
    const newName = databaseSeasonName.replace("SS", "Spring ");
    return newName;
  } else if (databaseSeasonName.startsWith("FW")) {
    const newName = databaseSeasonName.replace("FW", "Summer ");
    return newName;
  } else {
    return databaseSeasonName;
  }
}

export const highResImageURLFor = (articleNumber, allArticles) => {
  if (allArticles.length > 0) {
    let filteredArticles = allArticles.filter((article) => {
      return article.articleNumber === articleNumber;
    });
    if (filteredArticles.length > 0) {
      let url = filteredArticles[0]["imageURL"].replace(
        "WebImages",
        "HighResImages"
      );
      url = url.replace("_web", "");
      console.log(url);
      return url.replace("_web", "");
    }
  }
};
