import React from "react";

class NavBar extends React.Component {
  render() {
    return (
      <nav
        className="navbar is-fixed-top has-background-white-ter"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a className="navbar-item" href="#">
            <img
              src="adidas.png"
              alt="adidas Booking"
              //width="112"
              height="28"
            />
          </a>
        </div>
      </nav>
    );
  }
}

export default NavBar;
