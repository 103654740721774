import React, { Fragment } from "react";

class LeftMenu extends React.Component {
  changeSelectedCollection = (selectedCollection) => {
    this.props.updateSelectedCollection(selectedCollection);
  };

  collectionNameFormatted = (collection) => {};

  render() {
    return (
      <aside className='menu'>
        <ul className='menu-list'>
          {this.props.collections.map((collection) => {
            const collectionName = collection.split("-");
            const output = (
              <Fragment>
                <span>{collectionName[0]}</span>
                {collectionName.length > 1 ? (
                  <span className='collection-sub-text'>
                    {" "}
                    - {collectionName[1]}
                  </span>
                ) : (
                  ""
                )}
              </Fragment>
            );
            return (
              <li key={collection}>
                <a
                  className={
                    this.props.selectedCollection === collection
                      ? "is-active"
                      : ""
                  }
                  onClick={() => this.changeSelectedCollection(collection)}
                  style={{ borderRadius: "4px" }}
                >
                  {output}
                </a>
              </li>
            );
          })}
        </ul>
      </aside>
    );
  }
}

export default LeftMenu;
